<template>
  <app-data-table
    :title="$vuetify.lang.t('$vuetify.titles.list',[$vuetify.lang.t('$vuetify.company')])"

    :is-loading="isActionInProgress"
    csv-filename="Access"
    :headers="getTableColumns"
    :items="plans"
    :manager="'access'"
    :sort-by="['name']"
    @create-row="$router.push({'name' :'new_plan'})"
    @edit-row="handlerEditPlan"
    @delete-row="handlerDeletePlan"
    :sort-desc="[false, true]"
    multi-sort>
    <template v-slot:[`item.countries`]="{ item }">
      <v-chip v-for="(country, i) in item.countries" :key="i">{{country.country.name}}</v-chip>
    </template>
    <template v-slot:[`item.is_free`]="{ item }">
     <span v-if="item.is_free">{{$vuetify.lang.t('$vuetify.actions.yes')}}</span>
     <span v-else>{{$vuetify.lang.t('$vuetify.actions.no')}}</span>
    </template>
    <template v-slot:[`item.facture_period`]="{ item }">
      <template v-if="!item.is_free"></template>
      <v-chip v-if="parseFloat(item.facture_period.month)!==parseFloat(0.00)">
        {{$vuetify.lang.t('$vuetify.plan.month') + ': ' + parseFloat(item.facture_period.month)}}
      </v-chip>
      <v-chip v-if="parseFloat(item.facture_period.trimester)!==parseFloat(0.00)">
        {{$vuetify.lang.t('$vuetify.plan.trimester') + ': ' + parseFloat(item.facture_period.trimester)}}
      </v-chip>
      <v-chip v-if="parseFloat(item.facture_period.semester)!==parseFloat(0.00)">
        {{$vuetify.lang.t('$vuetify.plan.semester') + ': ' + parseFloat(item.facture_period.semester)}}
      </v-chip>
      <v-chip v-if="parseFloat(item.facture_period.annually)!==parseFloat(0.00)">
        {{$vuetify.lang.t('$vuetify.plan.annually') + ': ' + parseFloat(item.facture_period.annually)}}
      </v-chip>
      <v-chip v-if="parseFloat(item.facture_period.years_2)!==parseFloat(0.00)">
        {{$vuetify.lang.t('$vuetify.plan.years_2') + ': ' + parseFloat(item.facture_period.years_2)}}
      </v-chip>
      <v-chip v-if="parseFloat(item.facture_period.years_3)!==parseFloat(0.00)">
        {{$vuetify.lang.t('$vuetify.plan.years_3') + ': ' + parseFloat(item.facture_period.years_3)}}
      </v-chip>
      <v-chip v-if="parseFloat(item.facture_period.years_4)!==parseFloat(0.00)">
        {{$vuetify.lang.t('$vuetify.plan.years_4') + ': ' + parseFloat(item.facture_period.years_4)}}
      </v-chip>
      <v-chip v-if="parseFloat(item.facture_period.years_5)!==parseFloat(0.00)">
        {{$vuetify.lang.t('$vuetify.plan.years_5') + ': ' + parseFloat(item.facture_period.years_5)}}
      </v-chip>
    </template>
  </app-data-table>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'ListPlan',
  data () {
    return {
      showModal: false
    }
  },
  computed: {
    ...mapState('plan', ['plans', 'isActionInProgress']),
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.company'),
          value: 'name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.country'),
          value: 'countries'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.plan.is_free'),
          value: 'is_free'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.price'),
          value: 'facture_period'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  async created () {
    await this.getPlans()
  },
  methods: {
    ...mapActions('plan', ['getPlans', 'openEditModal', 'deletePlan']),
    handlerEditPlan ($event) {
      if (this.plans.filter(plan => plan.id === $event)[0].companies.length === 0) {
        this.openEditModal($event)
        this.$router.push({ name: 'edit_plan' })
      } else {
        this.$Swal
          .fire({
            title: this.$vuetify.lang.t('$vuetify.titles.delete', [
              this.$vuetify.lang.t('$vuetify.menu.admin_plan')
            ]),
            text: this.$vuetify.lang.t(
              '$vuetify.messages.warning_exist_subscriptions'
            ),
            icon: 'info',
            showCancelButton: false,
            confirmButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.accept'
            ),
            confirmButtonColor: 'red'
          })
      }
    },
    handlerDeletePlan ($event) {
      if (this.plans.filter(plan => plan.id === $event)[0].companies.length === 0) {
        this.$Swal
          .fire({
            title: this.$vuetify.lang.t('$vuetify.titles.delete', [
              this.$vuetify.lang.t('$vuetify.plan.name')
            ]),
            text: this.$vuetify.lang.t(
              '$vuetify.messages.warning_delete'
            ),
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.cancel'
            ),
            confirmButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.delete'
            ),
            confirmButtonColor: 'red'
          })
          .then(result => {
            if (result.value) this.deletePlan($event)
          })
      } else {
        this.$Swal
          .fire({
            title: this.$vuetify.lang.t('$vuetify.titles.delete', [
              this.$vuetify.lang.t('$vuetify.menu.admin_plan')
            ]),
            text: this.$vuetify.lang.t(
              '$vuetify.messages.warning_exist_subscriptions'
            ),
            icon: 'info',
            showCancelButton: false,
            confirmButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.accept'
            ),
            confirmButtonColor: 'red'
          })
      }
    }
  }
}
</script>

<style scoped>

</style>
